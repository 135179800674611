/*login ans signup*/
.formcontainer {
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
}

.userformcontainer {
    background-color: #a7bcff;
    height: 100vh;
    display: flex
}

.card1 {
    height: 1rem;
    width: 30%;
    margin: auto;
    text-align: center;
    background-color: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    border-radius: 1.5rem !important;

}

.card2 {
    height: 1rem;
    width: 80%;
    text-align: center;
    background-color: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    border-radius: 1.5rem !important;
    margin: auto;
    margin-top: 10rem;
}

.form-control {
    border-bottom-style: 1rem #a7bcff !important;
    border-right-style: none !important;
    border-left-style: none !important;
    border-top-style: none !important;
    margin-bottom: 2rem;
}

.avatarimage {
    width: 2rem;

}

input[type="file"] {
    display: none;
}

.custom-file-upload {

    border: none;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin: 1rem;
}

.custom-file-upload:hover {
    background-color: #ced4da;

}

.accountlink {
    color: #688bff !important;
}

.accountlink:hover {
    color: #2f2d52 !important;
}

.btn {
    width: 100%;
    background-color: #a7bcff !important;
    margin-bottom: 1rem;
}

.btn:hover {
    background-color: #2f2d52 !important;
}

@media (max-width: 1100px) {
    .card {
        width: 40%;
    }
}

@media (max-width: 770px) {
    .card {
        width: 45%;
    }
}

@media (max-width: 600px) {
    .card {
        width: 60%;
    }
}

@media (max-width: 450px) {
    .card {
        width: 100%;
    }
}

/*homepage*/
.pagestructure {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: #131324;


}

.homestructure {
    /* background-color: #a7bcff;

    display: flex;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;*/
    height: 100vh;
    width: 100%;
    background-color: #a7bcff;
    display: grid;
    grid-template-columns: 30% 70%;


    @media screen and (min-width: 720px) and (max-width: 1080px) {
        grid-template-columns: 35% 65%;
    }

}

.sideStructure {

    flex: 1 !important;
    background-color: #3e3c61;

    overflow-y: scroll;
    height: 100%
}

.chatStructure {
    flex: 2;
    background-color: #ddddf7;
    height: 100%;

}

/*Navbar*/
.navbar {
    background-color: #2f2d52;
    color: white;
    position: fixed
}

.nav-item1 {
    padding: 0.5rem;
}

.nav-link1 {
    font-size: 1.5rem;
    font-family: 'Montserrat-', sans-serif;
    color: white !important;
}

.nav-link1:hover {
    background-color: rgb(147, 147, 145);
    color: black !important;
    border-radius: 0.5rem;
    cursor: pointer
}

.navbar-toggler {
    background-color: white !important;
    font-size: 0.8rem !important;

}

.topBarImage {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 1rem;
}

/*search area*/
.searchBar {
    border-radius: 0 !important;

}



/*Chats in sidebar*/
.row1 {
    padding: 1rem;
    margin-top: 0.5rem;
}

.profilepic {
    width: 50px;
    height: 50px;
    border-radius: 50%;


    float: left;
    margin-top: 1rem
}

.name {
    color: white;
    font-weight: bolder;
    font-size: 1.5rem;

}



.eachchat {
    cursor: pointer;
    padding: 1rem;
}

.eachchat:hover {
    background-color: #2f2d52;
    ;

}

@media (max-width: 633px) {

    .row1 {
        padding-left: 0;
        margin-left: 0;
    }

    .profilepic {
        width: 2rem;
    }

    .name {
        color: white;
        font-weight: bolder;
        font-size: 1rem;

    }


}

/*Chat page*/
.chatbar {
    background-color: #5d5b8d;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    position: fixed !important;
    width: 100%
}

.container1 {
    padding: 1.3rem;
}

@media (max-width: 992px) {

    .container1 {
        padding: 0;
    }
}

@media (max-width: 378px) {

    .container1 {
        padding: 0.9rem;
    }
}

/* message*/
.messagestructure {
    padding-bottom: 3rem;
    height: 100%;
    scroll-behavior: smooth;
    overflow: auto;
    margin-bottom: 8rem;
    padding-bottom: 8rem;
}

.profilepicmessage {

    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;

    float: left
}


/*.message-content {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}*/

/*.message {

    background-color: white;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
    min-width: min-content;
}

.message1 {

    /*background-color: #688bff;
    color: white;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
    flex-direction: row-reverse;
}*/
.message {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}


.messageTop {
    display: flex;

}

.messageImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;

}

.messageText {

    color: #688bff;

    background-color: white;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
}

.messageBottom {
    font-size: 12px;
    margin-top: 10px;
}

.message.own {

    flex-direction: row-reverse;
}

.message.own .messageText {
    background-color: #688bff;
    color: white;

}

.message.own.messageImg {
    float: left
}


@media (max-width: 300px) {

    .messagestructure {
        padding-bottom: 1.5rem;
    }
}

/*input*/
.inputstructure {
    background-color: white;


    left: 10;
    bottom: 0;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;

    position: fixed;

}

.input {
    width: 60% !important;
    border: 1px solid #ced4da !important;
    margin: 0 !important;
}

.icons {
    font-size: 2rem;
    padding-right: 1rem;
    padding-left: 0.5rem;

}

.sendButton {
    background-color: white
}

.sendButton:hover {
    background-color: grey
}

.messagestructure::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.messagestructure::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.messagestructure::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.messagestructure::-webkit-scrollbar-thumb:hover {
    background: #555;
}